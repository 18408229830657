import React, { useState } from "react";
import { Search, LogOut, MoreVertical, Menu, Users } from "lucide-react";
import logo from "assets/images/logo.png";
import PopoverComponent from "components/popover";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { PUBLIC_URL } from "constants/constant";
import { useAppSelector } from "store/index";
import { Drawer } from "components/sidebar";
import RecentNotes from "features/recentNotes";
import SIPUsers from "features/SIPUsers";
import cn from "classnames";
import { useQuery } from "@tanstack/react-query";
import API from "api/index";

const dropdownOptions = [
  {
    name: "Uzeli Dashboard",
    link: "https://accountv2.growthzilla.com",
  },
  {
    name: "Uzeli Setup",
    link: "https://growthzilla.com/setup",
  },
  {
    name: "Order Equipment",
    link: " https://orders.growthzilla.com/app/store",
  },
  {
    name: "Equipment Order Status",
    link: "https://orders.growthzilla.com/app/store/pendingorders",
  },
  {
    name: "Sales Order Status",
    link: " https://orders.growthzilla.com/app/uzeli/pendingorders",
  },
];

const Navbar = ({ className }: { className?: string }) => {
  const [searchParam, setSearchParam] = useState<String>();
  const [displaySearchParam,setDisplaySearchParam] = useState<String>();
  const [leftSideBar, setLeftSideBar] = useState<boolean>(false);
  const [rightSideBar, setRightSideBar] = useState<boolean>(false);
  const { currentUser, isSalesUser } = useAppSelector(
    (state) => state.authUserReducer
  );
  const handleInputChange = (e: any) => {
    //window.alert(e.target.value);
    const value = e.target.value.toString();
    setDisplaySearchParam(value);
    //let value = e.target.value.toString();
    let newValue = "";

for (let i = 0; i < value.length; i++) {
    if (value[i] === "&") {
        newValue += "*and*";
    } else if (value[i] === " ") {
        newValue += "*space*";
    } else {
        newValue += value[i];
    }
}
    //const value = e.target.value.toString().replace('&','*and*').replace(' ','*space*');
    setSearchParam(newValue);
  };

  const handleKeyPress = (e: any) => {
    if (e.key === "Enter") {
      // console.log(searchParam.split(' ').join('+')
      // window.alert({searchParam});
      navigate(`/app/search?q=${searchParam}&tab=TIMELINE`, {
        state: { data: `${searchParam}` },
      });
    }
  };
  // fetch only one time
  const { data: openNotesCount } = useQuery({
    queryKey: ["openNotesCount", currentUser?.id],
    queryFn: async () => {
      try {
        const res = await API.get(`notes/user/count/${currentUser?.id}`, {
          params: {
            channel: isSalesUser ? "sales" : "",
          },
        });
        return Number(res.data.count);
      } catch (error: any) {
        toast.error(error?.response?.data?.message);
      }
    },
  });

  const navigate = useNavigate();
  return (
    <>
      <nav className={cn(className)}>
        <div className="flex justify-between items-center">
          {/* logo */}
          <div className="flex gap-2 text-white items-center">
            <button
              className="inline-block lg:hidden"
              onClick={() => setLeftSideBar(true)}
            >
              <Menu />
            </button>

            <div
              onClick={() => navigate("/app?tab=TIMELINE")}
              className="cursor-pointer"
            >
              <img className="max-w-[200px]" src={logo} alt="logo" />
            </div>
          </div>
          {/* Search bar */}
          <div className="bg-white hidden h-full px-4 py-2 lg:flex items-center rounded-md w-1/3">
            <div className="flex gap-2 w-full">
              <Search className=" text-[#9F9F9F]" />
              <input
                placeholder="Type your search keyword and hit enter"
                onChange={(e) => handleInputChange(e)}
                onKeyDown={handleKeyPress}
                className="focus:outline-none w-full"
                type="text"
              />
            </div>
          </div>
          {/* navlinks */}
          <ul className="flex gap-8  cursor-pointer text-white font-medium ">
            <div className="hidden lg:flex gap-8">
              <li
                onClick={() => navigate("/app?tab=TIMELINE")}
                className=" hover:border-b hover:border-white"
              >
                Home
              </li>
              <li
                onClick={() => navigate("/app/table")}
                className=" hover:border-b hover:border-white "
              >
                Table
              </li>
              <li
                onClick={() => navigate(`/app/myTickets?tab=TIMELINE`)}
                className=" hover:border-b hover:border-white inline-flex relative "
              >
                My Tickets
                {openNotesCount === 0 ? null : (
                  <span className="w-5 h-5 bg-red-500 text-white text-[12px] rounded-full flex justify-center absolute right-0 top-0 translate-x-5 -translate-y-1/2">
                    {openNotesCount}
                  </span>
                )}
              </li>
            </div>
            <PopoverComponent triggerButton={<MoreVertical />}>
              <div className=" flex flex-col text-[14px] lg:text-[16px]">
                <div
                  onClick={() => navigate("/app?tab=TIMELINE")}
                  className="lg:hidden flex cursor-pointer gap-2 items-center py-2 px-4 hover:bg-slate-100"
                >
                  Home
                </div>
                <div
                  onClick={() => navigate("/app/table")}
                  className="lg:hidden flex cursor-pointer gap-2 items-center py-2 px-4 hover:bg-slate-100"
                >
                  Table
                </div>
                <div
                  onClick={() => navigate(`/app/myTickets?tab=TIMELINE`)}
                  className="lg:hidden flex cursor-pointer gap-2 items-center py-2 px-4 border-b hover:bg-slate-100"
                >
                  My Tickets
                  {openNotesCount === 0 ? null : (
                    <span className="w-5 h-5 bg-red-500 text-white text-[11px] rounded-full flex justify-center items-center">
                      {openNotesCount}
                    </span>
                  )}
                </div>
                {dropdownOptions.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="flex cursor-pointer items-center py-2 px-4 hover:bg-slate-100"
                    >
                      <button
                        onClick={() => window.open(item.link, "_blank")}
                        className="focus:outline-none"
                      >
                        {item.name}
                      </button>
                    </div>
                  );
                })}

                <div className="flex cursor-pointer gap-2 items-center py-2 px-4 border-t hover:bg-slate-100">
                  <LogOut size={14} />
                  <button
                    onClick={() =>
                      window["signout" as keyof Window](PUBLIC_URL)
                    }
                    className=""
                  >
                    logout
                  </button>
                </div>
              </div>
            </PopoverComponent>
            <button
              className="inline-block lg:hidden"
              onClick={() => setRightSideBar(true)}
            >
              <Users />
            </button>
          </ul>
        </div>
      </nav>
      <Drawer
        type="left"
        className="bg-primary text-white"
        isOpen={leftSideBar}
        onClose={() => setLeftSideBar(false)}
      >
        <RecentNotes />
        {/* <BusinessInfo
          business={businessData}
        /> */}
      </Drawer>
      <Drawer
        type="right"
        closeButtonPosition="right"
        className="bg-white text-dark"
        isOpen={rightSideBar}
        onClose={() => setRightSideBar(false)}
      >
        <SIPUsers />
      </Drawer>
    </>
  );
};

export default Navbar;
