import {
  User,
  Clock,
  Play,
  Phone,
  Video,
  Angry,
  Heart,
  Check,
  X,
  Share2,
  Tag,
  Building,
  PhoneCallIcon,
} from "lucide-react";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { PSTtoLocal } from "../../../utils/dateConverter";
import { ITicket } from "../../../constants/Interfaces/notesInterface";
import toast from "react-hot-toast";
import Player from "components/audioPlayer";
import { useAppDispatch, useAppSelector } from "store/index";
import {
  updateContactByNotes,
  updateNoteReaction,
} from "store/notes/note_action";
import { useQueryClient } from "@tanstack/react-query";
import { dateDifference } from "utils/timeDifference";
import { useFormik } from "formik";
import CallModal from "components/modal/callModal";
import VideoCallModal from "components/modal/videoModal";
import { PUBLIC_URL } from "constants/constant";
import API from "api/index";
import cn from "classnames";
import { createCommentFunc, sendSms } from "store/notes/comment_action";
import Modal from "components/modal";

interface INoteCardInformationProps {
  notes: ITicket;
  className?: string;
}

const NoteCardInformation = ({
  notes,
  className,
}: INoteCardInformationProps) => {
  const [isContactEdit, setContactEdit] = useState(false);
  const dispatch = useAppDispatch();
  const queries = useQueryClient();

  const formik = useFormik({
    initialValues: {
      contactUUID: notes?.contactID,
      businessUUID: notes?.businessID,
      contactName: "",
    },
    onSubmit: async (values) => {
      console.log(notes?.contactID);
      try {
        if (!values?.contactUUID) {
          toast.error("There is no phone or email to save this contact");
        } else if (!values?.contactName) {
          toast.error("Please enter the valid name for this contact");
        } else {
          toast.promise(dispatch(updateContactByNotes(values)), {
            loading: "Updating contact...",
            success: () => "Contact updated!",
            error: (error) => `Error: ${error}`,
          });
        }
        queries.invalidateQueries(["batchNotes"]);
        queries.invalidateQueries(["businessBatchNotes"]);
      } catch (e: any) {
        toast.error(e);
      } finally {
        formik.setFieldValue("contactName", "");
      }
    },
  });

  const getType = () => {
    if (notes?.type === "EMAIL") {
      return notes?.Contact?.email ?? "UNKNOWN";
    }

    if (notes?.type === "TICKET") {
      return notes?.ticketBy ?? "UNKNOWN";
    }

    if (
      notes?.type === "SMS" ||
      notes?.type === "CALL_OUT" ||
      notes?.type === "CALL"
    ) {
      const contactName = notes?.Contact?.name ?? "";
      const contactPhone = notes?.Contact?.phone ?? "";

      return `${contactName} ${contactPhone}`.trim() || "UNKNOWN";
    }

    return "UNKNOWN";
  };

  const result = getType();

  return (
    <>
      <div className={cn(className)}>
        <div className="flex flex-col sm:flex-row lg:flex-col gap-4 px-4 py-4 max-w-[400px] w-full">
          <div className="flex flex-col gap-1">
            <p className="text-[#9F9F9F] text-[13px]">
              {notes?.type === "CALL_OUT" ? "To" : "From"}
            </p>
            <div className="flex gap-3 items-center">
              <div className="w-5 h-5">
                <User size={20} className="stroke-[1.5]" />
              </div>
              {isContactEdit ? (
                <div className="flex gap-2">
                  <input
                    type="text"
                    onChange={(e) =>
                      formik.setFieldValue("contactName", e.target.value)
                    }
                    className="px-2 py-1 text-[14px] w-[160px] border rounded-sm focus:outline-blue-500/40"
                    placeholder="Contact Name"
                  />
                  <button
                    onClick={() => formik.handleSubmit()}
                    className="text-black/40 hover:text-black/70"
                  >
                    <Check size={16} />
                  </button>
                  <button
                    onClick={() => setContactEdit(false)}
                    className="text-black/40 hover:text-black/70"
                  >
                    <X size={16} />
                  </button>
                </div>
              ) : (
                <p
                  className=""
                  onDoubleClick={() => {
                    if (!notes?.businessID) {
                      toast.error("Error: Set to a Business First");
                    } else {
                      setContactEdit(true);
                    }
                  }}
                >
                  {result}
                </p>
              )}
              <div className="flex flex-row items-center gap-3 min-w-fit ml-4">
                <Tag size={20} className="stroke-[1.5]" />
                <p className="">{notes?.ticketNumber}</p>
              </div>
              <div className="flex items-center ml-4 cursor-default">
                {notes?.firstCallResolution && (
                  <div
                    className={cn(
                      "px-2 py-1 text-[13px] rounded-full border",
                      notes?.firstCallResolution
                        ? "bg-green-50 text-green-500 border-green-500"
                        : "bg-red-50 text-red-500 border-red-500"
                    )}
                  >
                    {notes?.firstCallResolution ? "FCR" : "NFCR"}
                  </div>
                )}
              </div>
            </div>
          </div>
          {notes?.type === "CALL" && notes?.User && (
            <div className="flex flex-col  gap-1">
              <p className="text-[#9F9F9F]  text-[13px]">Answered By</p>
              <p className="">{`${notes?.User?.first_name} ${notes?.User?.last_name} `}</p>
              {/* <p className="text-[#9F9F9F]  text-[13px]">
                Call duration 4 min 25 sec
              </p> */}
            </div>
          )}

          <div
            className={`flex items-center gap-1  ${
              notes?.status === "OPEN"
                ? "bg-orange-50 text-orange-500 border-orange-500"
                : notes?.status === "UNMANAGED"
                ? "bg-red-50 text-red-500 border-red-500"
                : "bg-green-50 text-green-500 border-green-500"
            }  border text-[13px] rounded-full h-fit w-fit px-2 py-1"
              `}
          >
            <Clock size={16} />
            <p>
              {`${notes?.status || ""}
            ${
              notes?.status === "UNMANAGED"
                ? `for ${moment(PSTtoLocal(notes?.createdAt)).fromNow(true)}`
                : notes?.status === "OPEN"
                ? `for ${moment(PSTtoLocal(notes?.createdAt)).fromNow(true)}`
                : notes?.status === "CLOSED"
                ? Boolean(notes?.noteStatusUpdateAt)
                  ? `after ${dateDifference(
                      notes?.createdAt,
                      notes?.noteStatusUpdateAt
                    )}`
                  : ""
                : ""
            }
            
          `}
            </p>
          </div>
        </div>
        {/* action buttons */}
        <div className="w-full md:flex hidden md:justify-end">
          <NoteCardActions notes={notes} />
        </div>
      </div>
    </>
  );
};

export default NoteCardInformation;

export const NoteCardActions = ({
  notes,
  className,
}: {
  notes: ITicket;
  className?: string;
}) => {
  const [isHappyEnabled, setHappyEnabled] = useState(false);
  // const [isContactEdit, setContactEdit] = useState(false);
  const [screen, setScreen] = useState<"Confirm" | "On_Call">("Confirm");
  const [startVideoCall, setStartVideoCall] = useState(false);
  const [videoCallNumber, setVideoCallNumber] = useState(
    notes?.Contact?.phone?.toString() ?? "UNKNOWN NUMBER"
  );
  const [isSuccess, setSuccess] = useState<boolean>(false);
  const [playAudio, setPlayAudio] = useState<boolean>(false);
  const [makeCallModal, setMakeCallModal] = useState<boolean>(false);
  const [videoCall, setVideoCall] = useState<boolean>(false);
  const [audio, setAudio] = useState<any>([]);
  const [inviteLink, setInviteLink] = useState<string>("");
  const [isAngryEnabled, setAngryEnabled] = useState(false);
  const [token, setToken] = useState<string>();

  const dispatch = useAppDispatch();
  const queries = useQueryClient();
  const { currentUser, isSalesUser } = useAppSelector(
    (state) => state.authUserReducer
  );

  const handleDashboardNavigation = () => {
    let baseUrl = "https://accountv2.growthzilla.com/";
    let queryParams = new URLSearchParams();

    if (notes.Contact?.phone) {
        queryParams.append("gzup", notes.Contact.phone);
    }

    if (notes.Contact?.email) {
        // Remove `< >` and trim spaces
        //window.alert(JSON.stringify(notes.Contact));
        const contactEmail = notes.Contact.email.match(/<([^>]+)>/)?.[1]?.trim();
        let sanitizedEmail = contactEmail?contactEmail:notes.Contact.email;
        queryParams.append("gzue", sanitizedEmail);
    }

    // Fix `+` encoding issue
    let finalUrl = `${baseUrl}?${queryParams.toString()}`.replace(/%2B/g, "").replace(/%40/g,"@");

    // Open in a new tab
    window.open(finalUrl, "_blank");

    console.log("Generated URL:", finalUrl,"notes.User ",notes.User, "notes.Contact ",notes.Contact);
};


  useEffect(() => {
    const timer =
      isSuccess &&
      setTimeout(() => {
        setMakeCallModal(false);
        setScreen("Confirm");
        setSuccess(false);
      }, 3000);
    return () => {
      if (timer) return clearTimeout(timer);
    };
  }, [isSuccess]);

  const createComment = async (noteComment: string, type: string) => {
    try {
      await createCommentFunc(
        {
          noteId: notes?.id,
          userId: currentUser?.id,
          commentContent: noteComment,
        },
        type
      );
    } catch (err) {
      console.log(err);
    }
  };

  const handleReaction = async (type: any, value: any) => {
    await dispatch(
      updateNoteReaction({
        ROWID: notes?.id,
        [type]: value,
        business: { name: notes?.Business?.name },
        contact: { name: notes?.Contact?.name, phone: notes?.Contact?.phone },
        currentUser,
        note: {
          content: notes?.content,
          status: notes?.status,
          type: notes?.type,
        },
        user: notes?.User,
      })
    ).then(() => {
      queries.invalidateQueries(["batchNotes"]);
      type === "angry" ? setAngryEnabled(false) : setHappyEnabled(false);
    });
  };

  const handleCopy = () => {
    const url = window.location.hostname;
    const protocol = window.location.protocol;
    const copyText = `${protocol}//${url}/app/note/${notes?.id}`;
    navigator.clipboard.writeText(copyText).then(() => {
      toast.success("Success: Link Copied");
    });
  };

  useEffect(() => {
    if (notes?.Attachments) {
      const newAudio: any = [];
      for (let i = 0; i <= notes?.Attachments?.length - 1; i++) {
        if (notes?.Attachments[i].name === "call") {
          newAudio.push(notes?.Attachments[i].path);
        }
      }
      setAudio(newAudio);
    } else setAudio([]);
  }, [notes]);

  // Start video call function
  const openLens = async () => {
    const generateString = (length: number) => {
      const characters = "abcdefghijklmnopqrstuvwxyz0123456789";
      let result = "";
      const charactersLength = characters.length;
      for (let i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      return result;
    };
   

    const getInviteLink = (token: string) => {
      const curURL = PUBLIC_URL + "/app/video/" + notes?.id;
      console.log("curURL", curURL);
      return curURL.toString();
    };

    const tokenData = {
      room_name: generateString(5),
      mod_user: currentUser?.first_name,
      mod: true,
      user_name: notes?.Business.name ?? "Business",
      note_id: notes?.id,
    };

    console.log("tokenData", tokenData);

    try {
      const token = await API.post("/oauth/videoToken", tokenData);

      setToken(token.data.mod_token);
      setInviteLink(token.data.guest);
      setVideoCall(true);

      // Send video call invite link SMS to contact number
      toast.promise(
        sendSms({
          contactNumber: videoCallNumber,
          content: `Click on the link to join the video call: ${getInviteLink(
            tokenData.room_name
          )}`,
          smsFrom: isSalesUser ? currentUser?.phone : "",
        }),
        {
          loading: "Sending video call invite link...",
          success: "Video call invite link sent!",
          error: "Error: Failed to send video call invite link",
        }
      );

      // Create a comment for video call on the note
      const note_comment = `${currentUser?.first_name} ${currentUser?.last_name} has started a video call.`;
      createComment(note_comment, "INFO");

      return [token, getInviteLink(tokenData.room_name)];
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <div
        className={cn(
          className,
          "w-full flex px-4 py-3 gap-4 text-[#828282] border-t border-[#f2f4f7] justify-end items-center"
        )}
      >
        <div className="w-fit flex gap-2 max-h-16 justify-end">
          <button className="bg-primary text-white px-4 py-2 rounded-md" onClick={()=> handleDashboardNavigation()} >
            Dashboard
          </button>
        </div>
        {playAudio ? (
          <div className="w-full flex gap-2">
            <Player audio={audio} />
            <button onClick={() => setPlayAudio(false)}>
              <X />
            </button>
          </div>
        ) : (
          <div className="w-fit flex gap-6">
            <button
              className="text-black/40 hover:text-black/70"
              onClick={() => handleCopy()}
            >
              <Share2 size={20} />
            </button>

            <button
              onClick={() => {
                if (audio.length !== 0) {
                  setPlayAudio(true);
                } else {
                  toast.error("Error: Call Record not found");
                }
              }}
              className={`${
                audio.length === 0
                  ? "text-gray-300 cursor-not-allowed"
                  : "text-black/40 hover:text-black/70"
              }`}
            >
              <Play size={20} />
            </button>

            <button
              // disabled={}
              className={` ${
                notes?.type === "CALL" || notes?.type === "SMS"
                  ? "text-black/40 hover:text-black/70"
                  : "text-gray-300 cursor-not-allowed"
              }`}
              onClick={() => {
                if (notes?.type === "CALL" || notes?.type === "SMS") {
                  setMakeCallModal(true);
                } else {
                  toast.error(`Error: You cannot make call on ${notes?.type}`);
                }
              }}
            >
              <Phone size={20} />
            </button>

            <button
              onClick={() => setStartVideoCall(true)}
              className="text-black/40 hover:text-black/70"
            >
              <Video size={20} />
            </button>

            {isAngryEnabled ? (
              <div className="flex gap-2 justify-center items-center text-black/70">
                {`${notes?.angry ? " Undo?" : "Inform?"}`}
                <button
                  onClick={() => handleReaction("angry", !notes?.angry)}
                  className="flex justify-center rounded-sm w-6 h-6 items-center gap-2 border border-black/40 hover:bg-red-500 hover:text-white"
                >
                  <Check size={16} />
                </button>
                <button
                  onClick={() => setAngryEnabled(false)}
                  className="flex justify-center rounded-sm w-6 h-6 items-center gap-2 border border-black/40 hover:bg-green-500 hover:text-white"
                >
                  <X size={16} />
                </button>
              </div>
            ) : (
              <button
                className="text-black/40 hover:text-black/70"
                onClick={() => setAngryEnabled(true)}
              >
                <Angry
                  size={20}
                  className={`${
                    notes?.angry ? "fill-orange-500 stroke-white" : ""
                  }`}
                />
              </button>
            )}

            {isHappyEnabled ? (
              <div className="flex gap-2 justify-center items-center text-black/70">
                {`${notes?.happy ? " Undo?" : "Inform?"}`}
                <button
                  onClick={() => handleReaction("happy", !notes?.happy)}
                  className="flex justify-center rounded-sm w-6 h-6 items-center gap-2 border border-black/40 hover:bg-red-500 hover:text-white"
                >
                  <Check size={16} />
                </button>
                <button
                  onClick={() => setHappyEnabled(false)}
                  className="flex justify-center rounded-sm w-6 h-6 items-center gap-2 border border-black/40 hover:bg-green-500 hover:text-white"
                >
                  <X size={16} />
                </button>
              </div>
            ) : (
              <button
                className="text-black/40 hover:text-black/70"
                onClick={() => setHappyEnabled(true)}
              >
                <Heart
                  size={20}
                  className={`${
                    notes?.happy ? "fill-red-500 stroke-white" : ""
                  }`}
                />
              </button>
            )}
          </div>
        )}
      </div>

      <CallModal
        isOpen={makeCallModal}
        onClose={() => setMakeCallModal(false)}
        callingTo={notes?.Contact?.name || "Unknown"}
        businessID={notes?.businessID}
        businessName={notes?.Business?.name ?? "Unknown"}
        noteID={notes?.id}
        contactID={notes?.contactID}
        phoneNumber={notes?.Contact?.phone ?? ""}
      />

      {/* Start Video call modal */}
      <Modal isOpen={startVideoCall} onClose={() => setStartVideoCall(false)}>
        <div className="flex flex-col items-center gap-10 px-12 w-full">
          <div className="flex flex-col gap-2">
            <p className="text-xl font-semibold">
              Would you like to start a video call session with the client?
            </p>
            <p className="text-lg">{notes?.content}</p>
          </div>
          <div className="flex flex-col gap-3 items-start self-start w-full">
            <div className="flex items-center gap-2">
              <Tag size={20} />
              <p>{notes?.ticketNumber}</p>
            </div>
            <div className="flex items-center gap-2">
              <Building size={20} />
              <p>{notes.Business?.name ?? "UNKNOWN BUSINESS"}</p>
            </div>
            <div className="flex items-center gap-2">
              <PhoneCallIcon size={20} />
              <input
                className="border border-primary rounded px-2 py-1 w-48"
                value={videoCallNumber}
                onChange={(e) => setVideoCallNumber(e.target.value)}
              />
            </div>
          </div>
          <div className="flex gap-2 justify-end w-full">
            <button
              className="bg-transparent text-primary py-2 px-4 rounded border border-primary"
              onClick={() => setStartVideoCall(false)}
            >
              Cancel
            </button>
            <button
              className={`${
                videoCallNumber === "UNKNOWN NUMBER" || !videoCallNumber
                  ? "bg-primary/25 text-gray-500"
                  : "bg-primary text-white cursor-pointer"
              } py-2 px-4 rounded border border-primary`}
              disabled={
                videoCallNumber === "UNKNOWN NUMBER" || !videoCallNumber
              }
              onClick={() => {
                openLens();
                setStartVideoCall(false);
              }}
            >
              Start Video Call
            </button>
          </div>
        </div>
      </Modal>

      {/* Video call session Modal */}
      <VideoCallModal
        token={token}
        isOpen={videoCall}
        inviteLink={inviteLink}
        notes={notes}
        onClose={() => setVideoCall(false)}
        title={`Video Conference`}
      />
    </>
  );
};
