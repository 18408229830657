"use client";
import React from "react";
import Select from "react-select";

import {
  Column,
  Table,
  useReactTable,
  ColumnFiltersState,
  getCoreRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  sortingFns,
  getSortedRowModel,
  FilterFn,
  SortingFn,
  flexRender,
  FilterFns,
} from "@tanstack/react-table";

import {
  RankingInfo,
  rankItem,
  compareItems,
} from "@tanstack/match-sorter-utils";
import { ChevronDown, ChevronUp } from "lucide-react";
import { IBusiness, IBusinessOption } from "constants/Interfaces/notesInterface";
import { useAppSelector } from "store/index";
import { useNavigate } from "react-router-dom";

declare module "@tanstack/table-core" {
  interface FilterFns {
    fuzzy: FilterFn<unknown>;
  }
  interface FilterMeta {
    itemRank: RankingInfo;
  }
}

const fuzzyFilter: FilterFn<any> = (
  row: any,
  columnId: any,
  value: any,
  addMeta: any
) => {
  // Rank the item
  const itemRank = rankItem(row.getValue(columnId), value);

  // Store the itemRank info
  addMeta({
    itemRank,
  });

  // Return if the item should be filtered in/out
  return itemRank.passed;
};

// const fuzzySort: SortingFn<any> = (rowA: any, rowB: any, columnId: any) => {
//   let dir = 0;

//   // Only sort by rank if the column has ranking information
//   if (rowA.columnFiltersMeta[columnId]) {
//     dir = compareItems(
//       rowA.columnFiltersMeta[columnId]?.itemRank!,
//       rowB.columnFiltersMeta[columnId]?.itemRank!
//     );
//   }

//   // Provide an alphanumeric fallback for when the item ranks are equal
//   return dir === 0 ? sortingFns.alphanumeric(rowA, rowB, columnId) : dir;
// };

export function DataTable({ columns, data }: any) {
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );

  const businessIDsArray = data.reduce((acc:any, item:any) => {
    acc[item.businessID] = item.businessID; // Add businessID as both key and value
    return acc;
  }, {} as { [key: string]: string }); 

// Initialize accumulator as an empty object

  const columnOptions = columns.map((column: any) => ({
    value: column.accessorKey, // Assuming your columns have an accessorKey
    label: column.header,      // Assuming your columns have a header
  }));



  const [globalFilter, setGlobalFilter] = React.useState("");
  const navigate = useNavigate();
  const table = useReactTable({
    data,
    columns,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    state: {
      columnFilters,
      globalFilter,
    },
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    debugTable: true,
    debugHeaders: true,
    debugColumns: false,
  });
  const { businessList } = useAppSelector((state) => state.businessReducer);

  const businessOptionsListToday = businessList.filter((business: IBusiness) => {
    return businessIDsArray.hasOwnProperty(business.id);
});
  const businessOptionsList = businessOptionsListToday.map((business: IBusiness) => {

    return {
      value: business.id,
      label: business.name,
      isDisabled: business.status != null ? !business.status : false,
    };
  });

  // Separate enabled and disabled options
  const enabledOptions = businessOptionsList.filter((option: IBusinessOption) => !option.isDisabled);
  const disabledOptions = businessOptionsList.filter((option: IBusinessOption) => option.isDisabled);

  // Combine enabled options first, then disabled options
  const sortedBusinessOptions = [...enabledOptions, ...disabledOptions];

  const [selectedColumn, setSelectedColumn] = React.useState(null);
  const [filterValue, setFilterValue] = React.useState("");

  return (
    <div className="w-full min-h-[80vh] max-h-[80vh] flex flex-col gap-4 p-2">

      <div className="flex lg:flex-row flex-col w-full justify-end gap-2">
        <DebouncedInput
          value={globalFilter ?? ""}
          onChange={(value) => setGlobalFilter(String(value))}
          className=" px-2 font-lg border py-[6px] border-[#cccccc] rounded-[4px] hover:border-[#aeaeae]  focus:outline-[#2684ff] placeholder:text-[#868686]"
          placeholder="Search all columns"
        />

        <Select
          // isMulti
          isClearable
          placeholder="Search Table by Business"
          className="lg:w-[320px] w-full"
          // value={table?.getColumn("businessName")?.getFilterValue() as string}
          options={sortedBusinessOptions}
          onChange={(e: any) =>
            table?.getColumn("businessName")?.setFilterValue(e?.label)
            // console.log(e.label)
          }
          isOptionDisabled={(option: any) => option?.isDisabled}
        />
        <Select
          // isMulti
          isClearable
          placeholder="Search Table by Status"
          className="lg:w-[240px] w-full"
          // value={table?.getColumn("businessName")?.getFilterValue() as string}
          options={[
            {
              value: "OPEN",
              label: "OPEN"
            },
            {
              value: "CLOSED",
              label: "CLOSED"
            },
            {
              value: "UNMANAGED",
              label: "UNMANAGED"
            }
          ]}
          onChange={(e: any) =>
            table?.getColumn("status")?.setFilterValue(e?.label)
            // console.log(e.label)
          }
        />
      </div>
      <div className="flex lg:flex-row flex-col w-full justify-end gap-2">
        <Select
          isClearable
          placeholder="Select Column"
          className="lg:w-[240px] w-full"
          options={columnOptions}
          onChange={(selectedOption: any) => {
            setSelectedColumn(selectedOption?.value || null);
            setFilterValue(""); // Reset filter value when column changes
          }}
        />
        <DebouncedInput
          value={filterValue}
          onChange={(value) => {
            setFilterValue(String(value));
            if (selectedColumn) {
              table.getColumn(selectedColumn)?.setFilterValue(value);
            }
          }}
          className="px-2 font-lg border py-[6px] border-[#cccccc] rounded-[4px] hover:border-[#aeaeae] focus:outline-[#2684ff] placeholder:text-[#868686]"
          placeholder="Enter filter value"
        />

      </div>
      <div className="max-h-[80vh] overflow-y-auto">

        <table className="w-full bg-white ">
          <thead className=" bg-primary text-white font-normal text-[14px] rounded rounded-t-2xl sticky top-0">
            {table.getHeaderGroups().map((headerGroup: any) => (
              <tr className="mx-4" key={headerGroup.id}>
                {headerGroup.headers.map((header: any) => {
                  return (
                    <th
                      className=" text-left min-w-fit px-4 max-w-[280px] py-2"
                      key={header.id}
                    >
                      {header.isPlaceholder ? null : (
                        <>
                          <div
                            {...{
                              className: header.column.getCanSort()
                                ? "cursor-pointer select-none flex gap-2 items-center"
                                : "",
                              onClick: header.column.getToggleSortingHandler(),
                            }}
                          >
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                            {{
                              asc: <ChevronUp size={20} />,
                              desc: <ChevronDown size={20} />,
                            }[header.column.getIsSorted() as string] ?? null}
                          </div>
                          {/* {header.column.getCanFilter() ? (
                          <div>
                            <Filter column={header.column} table={table} />
                          </div>
                        ) : null} */}
                        </>
                      )}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody>

            {table?.getRowModel()?.rows?.map((row: any) => {
              return (
                <tr key={row.id} onClick={() => navigate(`/app/note/${row.original.id}`)} className="cursor-pointer hover:bg-gray-100">
                  {row.getVisibleCells().map((cell: any) => {
                    return (
                      <td
                        className="border text-left min-w-fit px-4 max-w-[280px] py-2"
                        key={cell.id}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {data.length === 0 && <p className="w-full text-center text-[16px] font-semibold">No data found !</p>}
      <div className="h-2" />
      {/* <div>{table?.getPrePaginationRowModel()?.rows?.length} Rows</div> */}
    </div>
  );
}

function Filter({
  column,
  table,
}: {
  column: Column<any, unknown>;
  table: Table<any>;
}) {
  const firstValue = table
    .getPreFilteredRowModel()
    .flatRows[0]?.getValue(column.id);

  const columnFilterValue = column.getFilterValue();

  const sortedUniqueValues = React.useMemo(
    () =>
      typeof firstValue === "number"
        ? []
        : Array.from(column.getFacetedUniqueValues().keys()).sort(),
    [column.getFacetedUniqueValues()]
  );

  return typeof firstValue === "number" ? (
    <div>
      <div className="flex space-x-2">
        <DebouncedInput
          type="number"
          min={Number(column.getFacetedMinMaxValues()?.[0] ?? "")}
          max={Number(column.getFacetedMinMaxValues()?.[1] ?? "")}
          value={(columnFilterValue as [number, number])?.[0] ?? ""}
          onChange={(value) =>
            column.setFilterValue((old: [number, number]) => [value, old?.[1]])
          }
          placeholder={`Min ${column.getFacetedMinMaxValues()?.[0]
              ? `(${column.getFacetedMinMaxValues()?.[0]})`
              : ""
            }`}
          className="w-24 border shadow rounded"
        />
        <DebouncedInput
          type="number"
          min={Number(column.getFacetedMinMaxValues()?.[0] ?? "")}
          max={Number(column.getFacetedMinMaxValues()?.[1] ?? "")}
          value={(columnFilterValue as [number, number])?.[1] ?? ""}
          onChange={(value) =>
            column.setFilterValue((old: [number, number]) => [old?.[0], value])
          }
          placeholder={`Max ${column.getFacetedMinMaxValues()?.[1]
              ? `(${column.getFacetedMinMaxValues()?.[1]})`
              : ""
            }`}
          className="w-24 border shadow rounded"
        />
      </div>
      <div className="h-1" />
    </div>
  ) : (
    <>
      <datalist id={column.id + "list"}>
        {sortedUniqueValues.slice(0, 5000).map((value: any) => (
          <option value={value} key={value} />
        ))}
      </datalist>
      <DebouncedInput
        type="text"
        value={(columnFilterValue ?? "") as string}
        onChange={(value) => column.setFilterValue(value)}
        placeholder={`Search... (${column.getFacetedUniqueValues().size})`}
        className="w-36 border shadow rounded"
        list={column.id + "list"}
      />
      <div className="h-1" />
    </>
  );
}

// A debounced input react component
function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}: {
  value: string | number;
  onChange: (value: string | number) => void;
  debounce?: number;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, "onChange">) {
  const [value, setValue] = React.useState(initialValue);

  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [value]);

  return (
    <input
      {...props}
      value={value}
      onChange={(e) => setValue(e.target.value)}
    />
  );
}
