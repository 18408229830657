import NotesOverviewItem from "./NotesOverviewItem";
import InfiniteScroll from "react-infinite-scroll-component";
import { ITicket, ITicketOverview } from "constants/Interfaces/notesInterface";
import * as HoverCard from "@radix-ui/react-hover-card";
import NotesOverviewItemContent from "./NotesOverviewItemContent";
import { PSTtoLocal } from "utils/dateConverter";

// Only show notes within last 24 horus
// Manually show all timings 12AM, 1AM, 2AM, .... 11PM, 12AM

const timeSlots = [
  { start: 0, end: 4, label: "12AM - 4AM" },
  { start: 4, end: 8, label: "4AM - 8AM" },
  { start: 8, end: 12, label: "8AM - 12PM" },
  { start: 12, end: 16, label: "12PM - 4PM" },
  { start: 16, end: 20, label: "4PM - 8PM" },
  { start: 20, end: 24, label: "8PM - 12AM" },
];

const NotesOverview = ({
  notesList,
  handleClick,
}: {
  notesList: ITicketOverview[];
  handleClick: (id: string) => void;
}) => {
  const filteredNotes = notesList.filter((note) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    return PSTtoLocal(note.updatedAt) > today;
    // return true;
  });

  const groupedNotes = timeSlots.map((slot) => {
    const notesInSlot = filteredNotes.filter((note) => {
      const updatedAt = PSTtoLocal(note.updatedAt);
      return (
        updatedAt.getHours() >= slot.start && updatedAt.getHours() < slot.end
      );
    });
    return { ...slot, notes: notesInSlot };
  });

  return (
    <aside className="flex flex-col justify-between sticky top-[72px] min-w-[9rem] py-2 gap-1.5 bg-white rounded-t-md mr-4 overflow-hidden max-h-[calc(100vh - 112px)]">
      <InfiniteScroll  
      scrollThreshold={0.8}
      loader={
        <div className="h-[20vh] bg-transparent flex items-center justify-center w-full">
          <div className="loader"></div>
        </div>
      }
      dataLength={notesList?.length || 0}
      hasMore={false}
      next={()=>{}}
      >
        {[...groupedNotes].map((slot) => (
          <div className="flex max-h-max gap-3 border-b-2 pb-2">
            <h2 className="pl-1 flex text-sm flex-col items-center justify-center text-gray-500 w-1/2">
              <span>{slot.label.split("-")[0]}</span>
              <span>-</span>
              <span>{slot.label.split("-")[1]}</span>
            </h2>
            <div className="flex flex-col justify-center w-full gap-0.5">
              {slot.notes.map((note) => (
                <HoverCard.Root key={note.id}>
                  <HoverCard.Trigger onClick={() => handleClick(note.id)}>
                    <NotesOverviewItem
                      note={note}
                      numberOfItems={slot.notes.length}
                    />
                  </HoverCard.Trigger>
                  <HoverCard.Portal>
                    <HoverCard.Content side="left" align="start" className="z-50">
                      <NotesOverviewItemContent note={note} />
                    </HoverCard.Content>
                  </HoverCard.Portal>
                </HoverCard.Root>
              ))}
            </div>
          </div>
        ))}
      </InfiniteScroll>
    </aside>
  );
};

export default NotesOverview;
