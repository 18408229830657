import LayoutWrapper from "components/layout";
import Main from "components/main";
import SIPUsers from "features/SIPUsers";
import NoteContainer from "features/noteContainer";
import RecentNotes from "features/recentNotes";
import React, { Suspense, useMemo, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import API from "api/index";
import { useAppSelector } from "store/index";
import { useQueryParams } from "hooks/useQueryParams";
import { TabStatus } from "store/tab/tabSlice";
import { ArrowLeft } from "lucide-react";
import SwitchTabs from "features/noteContainer/components/switchtabs";

const SwitchTabContent = [
  {
    value: TabStatus.TIMELINE,
    name: "All",
  },
  {
    value: TabStatus.OPEN,
    name: "Open Tickets",
  },
  {
    value: TabStatus.UNMANAGED,
    name: "Unmanaged Tickets ",
  },
  {
    value: TabStatus.CLOSED,
    name: "Closed Tickets",
  },
];

const SearchPage = () => {
  //   const { businessUUID } = useParams();
  const { getQueryParam, setQueryParam } = useQueryParams();
  // const [data, setData] = useState<any>([]);
  // const [error, setError] = useState<string>();
  // const location = useLocation();
  // const searchQuery = location.state?.data;
  let searchQuery = getQueryParam("q") || " ";
  // const [tabStatus, setTabStatus] = useState(getQueryParam('tab'));
  let tabStatus = getQueryParam("tab") || "";
  console.log(searchQuery);
  

  const [hasMore, setHasMore] = useState(true);
  //   const [page, setPage] = useState(1);
  const { isSalesUser, currentUser } = useAppSelector(
    (state) => state.authUserReducer
  );

  // useEffect(() => {
  //   const fetchData = async()=>{
  //     try{
  //       setData([]);
  //       setHasMore(true);
  //         const res = await API.get(`notes/searchNote`, {
  //         params: {
  //           // q: "extraordinary",
  //           q: searchQuery.split(" ").join("+"),
  //           channel: isSalesUser ? "SALES" : "",
  //         },
  //       });
  //       setHasMore(false);
  //       setData(res?.data);
  //     }catch(e:any){
  //       console.log(e?.response?.data);
  //       setError(e?.response?.data);
  //       setHasMore(false);
  //     }

  //   }

  //   fetchData();

  // }, [searchQuery])

  const { data } = useQuery({
    queryKey: ["searchNotes", searchQuery],
    queryFn: async () => {
      const res = await API.get(`notes/searchNote`, {
        params: {
          q: searchQuery.split(" ").join("+"),
          // q: searchQuery.split(" ").join("+"),
          channel: isSalesUser ? "SALES" : "",
        },
      });
      setHasMore(false);
      return res.data;
    },
  });
  const handleNext = () => {
    console.log("handleNext");
    // setPage((prevPage) => prevPage + 1);
  };
  const filteredData = useMemo(() => {
    return data?.filter((item: any) => item.status === tabStatus);
  }, [data, tabStatus]);
  //   console.log({ filteredData });

  // useEffect(() => {
  //   setQueryParam("tab", "TIMELINE");
  // }, []);

  //   console.log(businessUUID);
  return (
    <LayoutWrapper>
      <RecentNotes className="hidden lg:inline-block" />
      <Main>
        <div className="z-20 sticky top-0 flex gap-8 bg-white items-center justify-between pt-2 px-4">
          <div className="flex gap-4 items-center">
            <button onClick={() => window.history.back()}>
              <ArrowLeft size={24} />
            </button>
            <div className="flex items-center gap-2 text-primary text-[24px] font-bold">
              <h5> Search Results for "{searchQuery.replace(/(\*and\*)/g,'&').replace(/(\*space\*)/g," ")}"</h5>
            </div>
          </div>
          <Suspense fallback={<></>}>
            <SwitchTabs
              value={tabStatus}
              tablist={SwitchTabContent}
              onChange={(e: any) => setQueryParam("tab", e)}
            />
          </Suspense>
        </div>
        <NoteContainer
          hasMore={hasMore}
          handleNext={handleNext}
          noteList={tabStatus === "TIMELINE" ? data : filteredData}
          noDataFound={data?.length}
        />
      </Main>
      <SIPUsers className="hidden lg:inline-block" />
    </LayoutWrapper>
  );
};

export default SearchPage;
