import { useQuery } from "@tanstack/react-query";
import { ColumnDef } from "@tanstack/react-table";
import API from "api/index";
import { DataTable } from "components/table";
import moment, { Moment, MomentLongDateFormat } from "moment-timezone";
import React, { useState } from "react";
import { useAppSelector } from "store/index";
import DatePicker from "react-datepicker";
import {
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  ChevronUp,
} from "lucide-react";

const formatData = (data: any[]) =>
  data?.map((x, index: number, self) => {
    return { ...x, ticketNo:x.ticketNumber };
  });

export const TableComponent = () => {
  const columns = React.useMemo<ColumnDef<any, any>[]>(
    () => [
      {
        header: "Ticket #",
        accessorKey: "ticketNo",
        footer: (props: any) => props.column.id,
        cell: (info: any) => info.getValue(),
      },

      {
        header: "Created Time",
        accessorKey: "createdAt",
        footer: (props: any) => props.column.id,
        cell: (info: any) => {
          info.getValue();
          return (
            <p>
              {moment
                .tz(
                  info.row.original.createdAt,
                  "YYYY-MM-DD hh:mm:ss",
                  "America/Los_Angeles"
                )
                .format("YYYY-MM-DD hh:mm:SS A")}
            </p>
          );
        },
        id: "createdAt",
        // accessorFn: (row:any) => row.lastName,
        // filterFn: 'fuzzy',
        // sortingFn: fuzzySort,
      },
      {
        header: "Business Name",
        accessorKey: "businessName",
        footer: (props: any) => props.column.id,
        cell: (info: any) => info.getValue(),
        id: "businessName",
        // accessorFn: (row:any) => row.lastName,
      },
      {
        header: "Contact",
        accessorKey: "contactName",
        footer: (props: any) => props.column.id,
        cell: (info: any) => info.getValue(),
        id: "contactName",
        // accessorFn: (row:any) => row.lastName,
      },
      {
        header: "Type",
        accessorKey: "type",
        footer: (props: any) => props.column.id,
        cell: (info: any) => info.getValue(),
        id: "type",
        // accessorFn: (row:any) => row.lastName,
      },
      {
        header: "Status",
        accessorKey: "status",
        footer: (props: any) => props.column.id,
        cell: (info: any) => {
          info.getValue();
          return (
            <div
              className={`flex items-center gap-1  ${
                info.row.original.status === "OPEN"
                  ? "bg-orange-50 text-orange-500 border-orange-500"
                  : info.row.original.status === "UNMANAGED"
                  ? "bg-red-50 text-red-500 border-red-500"
                  : "bg-green-50 text-green-500 border-green-500"
              }  border text-[13px] rounded-full  w-fit px-2 py-1 "
                    `}
            >
              <p>{info.row.original.status}</p>
            </div>
          );
        },
        id: "status",
        // accessorFn: (row:any) => row.lastName,
      },

      {
        header: "Closed Time",
        accessorKey: "closedAt",
        footer: (props: any) => props.column.id,
        cell: (info: any) => {
          info.getValue();
          return (
            <p>
              {info.row.original.status === "CLOSED"
                ? moment
                    .tz(
                      info.row.original.modifiedAt,
                      "YYYY-MM-DD hh:mm:ss",
                      "America/Los_Angeles"
                    )
                    .format("YYYY-MM-DD hh:mm:SS A")
                : "-"}
            </p>
          );
        },
        id: "closedAt",
      },

      {
        header: "Action by",
        accessorKey: "userFullName",
        footer: (props: any) => props.column.id,
        cell: (info: any) => info.getValue(),
        id: "userFullName",
        // accessorFn: (row:any) => row.lastName,
      },
      {
        header: "Preview",
        accessorKey: "content",
        footer: (props: any) => props.column.id,
        cell: (info: any) => info.getValue(),
        id: "content",
        // accessorFn: (row:any) => row.lastName,
      },
      {
        header: "Reaction",
        accessorKey: "angry",
        footer: (props: any) => props.column.id,
        cell: (info: any) => info.getValue(),
        enableSorting: false,
        id: "angry",
        // accessorFn: (row:any) => row.lastName,
      },
    ],
    []
  );
  const { currentUser, isSalesUser } = useAppSelector(
    (state) => state.authUserReducer
  );
  // const [startDate,setStartDate] = useState<Date>( new Date(moment().tz('America/Los_Angeles').format()));
  //current date in pst timezone

  const [startDate, setStartDate] = useState<moment.Moment>(
    moment().tz("America/Los_Angeles")
  );
  console.log(startDate);
  const showActionedBy = !isSalesUser || currentUser?.type === "ADMIN";
  const { data, isLoading } = useQuery({
    queryKey: ["tableData", startDate],
    queryFn: async () => {
      const res = await API.get("/table", {
        params: {
          date: startDate.format("YYYY-MM-DD"),
          channel: isSalesUser ? "SALES" : undefined,
          userID:
            isSalesUser && currentUser?.type !== "ADMIN"
              ? currentUser?.id
              : undefined,
        },
      });
      return formatData(res.data);
    },
  });

  console.log(data);
  console.log(
    moment(startDate).format("YYYY-MM-DD") === moment().format("YYYY-MM-DD")
  );
  // return <p>hello table page</p>
  return (
    <>
      {isLoading ? (
        <div className=" h-[20vh]  bg-transparent  flex items-center justify-center">
          <div className="loader"></div>
        </div>
      ) : (
        <div className="w-full flex flex-col gap-4 items-center">
          <DataTable columns={columns} data={data || []} />
          <div className="flex items-center gap-2">
            <button
              className="border bg-white rounded-md p-2"
              onClick={() =>
                setStartDate(moment(startDate).subtract(1, "days"))
              }
            >
              <ChevronLeft />
            </button>
            <div className="border bg-white cursor-pointer px-4 py-2">
              <DatePicker
                selected={startDate.toDate()}
                onChange={(date: any) => setStartDate(moment(date))}
                dateFormat="MM/dd/yyyy"
                maxDate={new Date()}
                onChangeRaw={(event: any) => setStartDate(event.target.value)}
              />
            </div>
            <button
              className="border bg-white rounded-md p-2"
              onClick={() => setStartDate(moment(startDate).add(1, "days"))}
              disabled={
                moment(startDate).format("YYYY-MM-DD") ===
                moment().tz("America/Los_Angeles").format("YYYY-MM-DD")
              }
            >
              <ChevronRight
                className={` ${
                  moment(startDate).format("YYYY-MM-DD") ===
                  moment().tz("America/Los_Angeles").format("YYYY-MM-DD")
                    ? "text-gray-300"
                    : ""
                }`}
              />
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default TableComponent;
