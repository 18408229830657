export interface IBusiness {
  id: string;
  name: string;
  address: string | null;
  phone: number | null;
  email: string | null;
  state: string | null;
  city: string | null;
  zipcode: number | null;
  status?:boolean;
}
export interface IBusinessOption{
  value:string;
  label:string;
  isDisabled:boolean;
}

export interface ISupportEmail {
  CREATEDTIME: string;
  CREATORID: string;
  MODIFIEDTIME: string;
  ROWID: string;
  Reference: string;
  cc: string;
  email_body: string;
  in_reply_to: string;
  messageId: string;
  receiver_email_address: null | string;
  sender_email_address: string;
  subject: string;
}

export interface IUser {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  
}

export interface IComment {
  Attachments: IAttachments[];
  User: IUser;
  content: string;
  createdAt: string;
  id: string;
  noteID: string;
  supportEmail: ISupportEmail;
  support_emailID: string;
  type:
    | "INFO"
    | "COMMENT"
    | "EMAIL"
    | "SMS"
    | "VIDEO"
    | "MERGE_INFO"
    | "MERGE_COMMENT";
  updatedAt: string;
  userID: string;
}

export interface IContact {
  id: string;
  name: string;
  email: string;
  phone: string;
  businessID: string;
}

export interface IAttachments {
  id: string | null;
  name: string | null;
  path: string | null;
}

enum TicketType {
  "SMS",
  "EMAIL",
  "TICKET",
  "CALL",
  "CALL_OUT",
}
export interface ITicket {
  Attachments: IAttachments[];
  Business: IBusiness;
  Comments: IComment[];
  Contact: IContact;
  SupportEmail: ISupportEmail | null;
  User: IUser;
  noteStatusUpdateAt: string | null;
  angry: boolean;
  businessID: string;
  call_id: string | null;
  contactID: string;
  content: string;
  createdAt: string;
  happy: boolean;
  id: string;
  status: string;
  support_emailID: string | null;
  ticketBy: string;
  type: keyof typeof TicketType;
  updatedAt: string;
  userID: string;
  ticketNumber?: string;
  firstCallResolution?: boolean; // First Call Resolution
}

export interface ITicketOverview {
  id: string
  status: string
  type: string
  call_id: any
  content: string
  createdAt: string
  updatedAt: string
  noteStatusUpdateAt: any
  businessID: string
  contactID: string
  userID: any
  ticketBy: any
  angry: boolean
  happy: boolean
  ticketNumber: string
  firstCallResolution: any
  Business: IBusiness
  Contact: IContact
  User: any
}

export enum BusinessMetaType {
  ACCOUNT = "ACCOUNT",
  HARDWARE = "HARDWARE",
}

export interface IBusinessMeta {
  id: string;
  content: string;
  title: string;
  type: keyof typeof BusinessMetaType;
}

export interface IBusinessDetails {
  id: string;
  name: string;
  address: string | null;
  state: string | null;
  city: string | null;
  zipcode: number | null;
  business_id: string;
  contacts: IContact[];
  BusinessMetas: IBusinessMeta[];
  Notes: ITicket[];
}
